import React from "react";
import { Navbar, Image } from "react-bootstrap";

export default class AppNavbar extends React.Component {
  render() {
    return (
      <Navbar sticky="top" bg="white">
        <Navbar.Brand href="/">
          <Image
            src="/images/app_icon.png"
            className="d-inline-block align-top"
            width="36"
            height="36"
          />
        </Navbar.Brand>
      </Navbar>
    );
  }
}
