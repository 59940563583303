import React from "react";
import { Helmet } from "react-helmet";

export default class NotFound extends React.Component {
  render() {
    return (
      <div className="text-center">
        <Helmet>
          <title>Page Not Found - Huda</title>
        </Helmet>
        <h1 className="mt-5 text-info display-1">404</h1>
        <h5 className="text-secondary">Page not found!</h5>
        <a href="/">Home</a>
      </div>
    );
  }
}
