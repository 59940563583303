import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "./Home";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsService from "./TermsService";
import NotFound from "./NotFound";
import AcknowledgementsIOS from "./AcknowledgementsIOS";
import AcknowledgementsAndroid from "./AcknowledgementsAndroid";
import Donation from "./Donation";
import DonationComplete from "./DonationComplete";
import Store from "./Store";

const Router = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/privacy-policy" component={PrivacyPolicy} />
      <Route path="/terms-service" component={TermsService} />
      <Route path="/acknowledgements-ios" component={AcknowledgementsIOS} />
      <Route path="/donation" component={Donation} />
      <Route path="/donation/complete" component={DonationComplete} />
      <Route path="/store" component={Store} />
      <Route
        path="/acknowledgements-android"
        component={AcknowledgementsAndroid}
      />
      <Route component={NotFound} />
    </Switch>
  </BrowserRouter>
);

export default Router;
