import React from "react";

export default class Store extends React.Component {
  componentDidMount() {
    window.location.href = "https://hudastoremy.yezza.store";
  }
  render() {
    return <div className="p-4 text-secondary">Redirecting...</div>;
  }
}
