import React from "react";
import ReactDOM from "react-dom";
import "./css/index.css";
import Router from "./components/Router";
import ReactGA from "react-ga";

const isProduction = process.env.NODE_ENV === "production";
if (isProduction) {
  ReactGA.initialize("UA-146633329-1");
  ReactGA.pageview(window.location.pathname + window.location.search);
}

ReactDOM.render(<Router />, document.getElementById("root"));
