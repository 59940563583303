import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";

export default class DonationComplete extends React.Component {
  render() {
    return (
      <div className="text-center">
        <Helmet>
          <title>Donation - Complete</title>
        </Helmet>
        <Container>
          <h5 className="mt-5 px-5">
            Thank you for your donation. May it be a continuous charity for you
            and may Allah grant goodness in this world and hereafter. Thank you.
            جزاك الله خيراً
          </h5>
          <a href="/" className="btn btn-primary mt-3">
            Home
          </a>
        </Container>
      </div>
    );
  }
}
