import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import Footer from "./Footer";
import "../css/home.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";

export default class Home extends React.Component {
  componentDidMount() {
    window.addEventListener("load", () => {
      AOS.init({
        once: true
      });
    });
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Huda - Prayer Times App</title>
        </Helmet>
        <div>
          <div className="section-hero">
            <Container>
              <Row className="align-items-center justify-content-between">
                <Col
                  md={6}
                  className="hero-left-col"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <Image src="/images/app_icon.png" className="app-icon" />
                  <Image src="/images/logo_main.svg" className="app-logo" />
                  <h5 className="mt-3 mb-4 text-description">
                    Islamic prayer times, qibla direction,<br></br>nearby
                    mosques, doas & more.
                  </h5>
                  <a
                    href="https://itunes.apple.com/app/id1483923871?mt=8"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Image
                      src="/images/button_appstore.svg"
                      className="mr-2 my-1 btn-download btn-scale"
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.hudaapp.android"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Image
                      src="/images/button_google_play.svg"
                      className="my-1 btn-download btn-scale"
                    />
                  </a>
                </Col>
                <Col md={6} className="px-5">
                  <Image
                    src="/images/hero.png"
                    alt="device"
                    fluid
                    className="img-hero"
                    data-aos="zoom-in"
                    data-aos-duration="1500"
                  />
                </Col>
              </Row>
            </Container>
            <div className="divider-arc">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="30px"
                viewBox="0 0 100 100"
                version="1.1"
                preserveAspectRatio="none"
              >
                <path
                  d="M0,0 C16.6666667,66 33.3333333,99 50,99 C66.6666667,99 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z"
                  fill="#fff"
                ></path>
              </svg>
            </div>
          </div>
          <Container className="pt-5">
            <h3 className="text-center mt-5 text-blue">FEATURES</h3>
            <hr className="hr-divider mb-4" />
            <Row>
              <Col className="px-5 py-3 text-center" md={4} sm={6} xs={12}>
                <div className="d-inline-flex align-items-center mb-2">
                  <img
                    className="features-icon"
                    src="/images/ic_prayer_times.svg"
                    alt=""
                  />
                  <h5 className="mb-0 ml-3">Prayer Times</h5>
                </div>
                <p className="text-light-gray">
                  Get accurate prayer times with official database for Malaysia,
                  Singapore & Brunei
                </p>
              </Col>
              <Col className="px-5 py-3 text-center" md={4} sm={6} xs={12}>
                <div className="d-inline-flex align-items-center mb-2">
                  <img
                    className="features-icon"
                    src="/images/ic_qibla.svg"
                    alt=""
                  />
                  <h5 className="mb-0 ml-3">Qibla</h5>
                </div>
                <p className="text-light-gray">
                  Get direction towards Ka’bah right from your phone using
                  compass or direction on map
                </p>
              </Col>
              <Col className="px-5 py-3 text-center" md={4} sm={6} xs={12}>
                <div className="d-inline-flex align-items-center mb-2">
                  <img
                    className="features-icon"
                    src="/images/ic_hisnul.svg"
                    alt=""
                  />
                  <h5 className="mb-0 ml-3">Hisnul Muslim</h5>
                </div>
                <p className="text-light-gray">
                  Read and search from hundred of dua’s (supplications) derived
                  from the Qur’an and Sunnah.
                </p>
              </Col>
              <Col className="px-5 py-3 text-center" md={4} sm={6} xs={12}>
                <div className="d-inline-flex align-items-center mb-2">
                  <img
                    className="features-icon"
                    src="/images/ic_customization.svg"
                    alt=""
                  />
                  <h5 className="mb-0 ml-3">Customization</h5>
                </div>
                <p className="text-light-gray">
                  Customize your app by changing the themes, app icon, choose
                  prefered athan sounds & more
                </p>
              </Col>
              <Col className="px-5 py-3 text-center" md={4} sm={6} xs={12}>
                <div className="d-inline-flex align-items-center mb-2">
                  <img
                    className="features-icon"
                    src="/images/ic_mosque.svg"
                    alt=""
                  />
                  <h5 className="mb-0 ml-3">Mosque Finder</h5>
                </div>
                <p className="text-light-gray">
                  Find and get direction to the nearest mosques around you using
                  Google Maps, Wave or Apple Map
                </p>
              </Col>
              <Col className="px-5 py-3 text-center" md={4} sm={6} xs={12}>
                <div className="d-inline-flex align-items-center mb-2">
                  <img
                    className="features-icon"
                    src="/images/ic_widget.svg"
                    alt=""
                  />
                  <h5 className="mb-0 ml-3">Widget</h5>
                </div>
                <p className="text-light-gray">
                  Quickly glance today’s prayer times on your phone via Today
                  Widget
                </p>
              </Col>
            </Row>
          </Container>
          <Container className="pt-5">
            <Row className="row-download align-items-center">
              <Col sm={12} md={7} className="description-column text-center">
                <h2 className="download-title">Download the free app now</h2>
                <div className="download-subtitle">
                  Available on iOS and Android
                </div>
              </Col>
              <Col sm={12} md={5} className="text-center py-3">
                <a
                  href="https://itunes.apple.com/app/id1483923871?mt=8"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Image
                    src="/images/button_appstore.svg"
                    className="mr-2 my-1 btn-download btn-scale"
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.hudaapp.android"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Image
                    src="/images/button_google_play.svg"
                    className="my-1 btn-download btn-scale"
                  />
                </a>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
    );
  }
}
