import React from "react";
import { Container, Navbar, Row, Col, Image, Button } from "react-bootstrap";

export default class Donation extends React.Component {
  render() {
    return (
      <div>
        <Navbar sticky="top" className="donation-navbar">
          <div className="donation-text">Donation</div>
        </Navbar>
        <Container>
          <Row className="align-items-center justify-content-between p-3">
            <Col md={6}>
              <h4 className="my-3">Huda App Donation</h4>
              <p>
                To donate to Huda, please select from the options below. May it
                be a Sadaqah Jariyah for you and may Allah grant goodness in
                this world and hereafter. Ameen.
              </p>
              <p>Thank you for your kind support. جزاك الله خيراً</p>
              <Button
                variant="secondary"
                size="lg"
                className="mt-3"
                href="https://www.billplz.com/huda-donation"
              >
                Online Banking
              </Button>
              <p className="my-3">or</p>
              <Button
                variant="light"
                size="lg"
                className="mb-3"
                href="https://paypal.me/hudadonation"
              >
                <Image src="https://www.paypalobjects.com/webstatic/mktg/Logo/pp-logo-100px.png" />
              </Button>
            </Col>
            <Col md={6} className="p-3">
              <Image src="/images/donation_devices.png" fluid />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
