import React from "react";
import { Container, Image } from "react-bootstrap";
import "../css/footer.css";

const Footer = () => (
  <div className="footer">
    <Container className="py-5 text-center">
      <div className="p-4">
        <a
          href="https://www.facebook.com/apphuda/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <Image src="/images/social_fb.svg" className="px-2 btn-scale" />
        </a>
        <a
          href="https://twitter.com/app_huda"
          rel="noopener noreferrer"
          target="_blank"
        >
          <Image src="/images/social_twitter.svg" className="px-2 btn-scale" />
        </a>
        <a
          href="https://www.instagram.com/apphuda/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <Image
            src="/images/social_instagram.svg"
            className="px-2 btn-scale"
          />
        </a>
      </div>
      <div>
        <span className="footer-legal-link line-right">
          © {new Date().getFullYear()} MRM Digital
        </span>
        <a className="footer-legal-link line-right" href="/privacy-policy">
          Privacy Policy
        </a>
        <a className="footer-legal-link" href="/terms-service">
          Terms of Service
        </a>
      </div>
    </Container>
  </div>
);

export default Footer;
